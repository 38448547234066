@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.column-team a {
    padding-bottom: 13%;
}

.team-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 16px;
}

.team-full-width-image {
    width: 100%;
    display: block;
    margin: auto;
}

.team-container .first-row h2 {
    font-family: 'Poppins-Bold';
    font-size: 42px;
    color: #a6a6a6;
    text-align: center;
    width: 100%;
}

.second-row-team {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.column-team {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-gap: 4px;
    align-items: center;
    justify-items: center;
    border: none;
    border-radius: 10px;
    background-color: none;
}

.second-row-team .column-team img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 3px;
}

.second-row-team .column-team h3 {
    font-family: "Poppins-Medium";
    font-size: 28px;
    color: #f0f0f0;
    margin-bottom: -10px;
}

.second-row-team .column-team p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
}

/* Your existing font-face and other styles */

/* Add media query for mobile devices */
@media screen and (max-width: 768px) {
    .team-container {
      grid-template-rows: auto auto auto; /* Adjust the number of rows for mobile */
    }
  
    .team-full-width-image {
      width: 100%;
      height: auto; /* Adjust the height as needed */
      display: block;
      margin: auto;
    }
  
    .second-row-team {
      grid-template-columns: 1fr; /* Display only one column for mobile */
      grid-gap: 16px;
      padding: 0 16px; /* Adjust the padding as needed */
    }
  
    .column-team {
      width: 100%; /* Occupy full width */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      border: none;
      border-radius: 10px;
      background-color: none;
      padding-bottom: 16px; /* Adjust the padding as needed */
    }
  
    .second-row-team .column-team img {
      width: 100%; /* Adjust the image width as needed */
      height: auto; /* Adjust the image height as needed */
      object-fit: cover;
      border-radius: 3px;
    }
  
    .second-row-team .column-team h3 {
      font-size: 24px; /* Adjust the font size as needed */
      margin-bottom: 5px; /* Adjust the margin as needed */
    }
  
    .second-row-team .column-team p {
      font-size: 16px; /* Adjust the font size as needed */
    }
  
    .column-team a {
      padding-bottom: 8px; /* Adjust the padding as needed */
    }
  }
  