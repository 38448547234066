@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.fantasy-page {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 16 px;
}

.fantasy-page .row-one img {
    width: 100%;
    height: auto;
    padding-bottom: 7%;
}

.fantasy-page h2 {
    font-family: 'Poppins-Bold';
    color: #f0f0f0;
    font-size: 42px;
    text-align: center;
}

.fantasy-page p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
}

.fantasy-page h4 {
    font-family: 'Poppins-Medium';
    font-size: 20px;
    color: #a6a6a6;
}

.fantasy-page li {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
    margin-bottom: 2%;
}

.fantasy-page .column-text {
    padding-left: 7%;
}

.fantasy-page .column-image {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.fantasy-page .text-left-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 6%;
}


.fantasy-page .img-left-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 6%;
}
.fantasy-page .custom-link {
    color: inherit;
    text-decoration: none;
}

.fantasy-page .custom-link :hover {
    text-decoration: underline;
}

.fantasy-page .img-left-row .column-text {
    padding-top: 7%;
}

.fantasy-page .spec-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: transparent;
    border-style: solid;
    border-radius: 5px;
}

.fantasy-page .spec-button:hover {

    background-color:rgba(5, 165, 21, 0.25);
}