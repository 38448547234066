.marketplace-page {
    display: grid;
    grid-template-rows: auto auto;
}

.marketplace-page .first-row img {
    width: 100%;
}

.marketplace-page .nft-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    color: #f0f0f0;
    gap: 20px;
}

.marketplace-page .nft-tiles .nft-tile {
    border: 2px solid #f0f0f0;
    padding: 20px;
    border-radius: 5px;
}
/*
.marketplace-page .nft-tiles .nft-tile img {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    object-fit: cover;
    display: block;
}
*/
.image-container {
    width: 100%;
    padding-bottom: 75%; /* Adjust this value to change the aspect ratio */
    position: relative;
}

.image-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}