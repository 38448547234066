
.driverz-page {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.form-settings {
    padding-bottom: 7%;
}


.form-group {
    display: grid;
    grid-gap: 4px;
    grid-template-rows: 1fr 1fr 1fr;
    padding-bottom: 3%;
}

.form-group-2 {
    display: grid;
    grid-gap: 4px;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 3%;
}

.form-group-1 {
    display: grid;
    grid-template-rows: 1fr;
}

.form-check {
    padding-left: 12.5%;
}
  
.form-row {
    display: grid;
    grid-template-rows: 1fr;
}

.text-white-2 {
    font-family: 'Poppins-Bold';
    font-size: 36px;
    color: #f0f0f0;
    padding-left: 12.5%;
}

.text-gray {
    font-family: 'Poppins-Medium';
    font-size: 24px;
    color: #a6a6a6;
    padding-left: 12.5%;
}

.text-gray-spec {
    font-family: 'Poppins-Medium';
    font-size: 20px;
    color: #a6a6a6;
    padding-left: 12.5%;
}
/*
.modal-backdrop {
    background-color: #a6a6a6;
    z-index: 9998;
} */

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 50vh;
    overflow-y: auto;
    background-color: #a6a6a6;
    z-index: 9999; /* Adjust the z-index as needed */
}

.modal-container .modal-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.modal-container .modal-row img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto; /* To center the images if they are smaller than the max-width and max-height */
}

.row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding-left: 12.5%;
    padding-right: 12.5%;
    padding-bottom: 3%;
}

.col-md-2 {
    display: grid;
    grid-template-rows: 20% 50% auto auto;
    padding-bottom: 7%;
    padding-right: 11%;
}

.col-md-2 .text-white {
    height: 100%;
}

.col-md-2 .row-1 { 
    padding-bottom: 24%;
}

.col-md-2 .row-2 {
    padding-bottom: 27%;
}

.col-md-2 .row-3 {
    padding-bottom: none;
    width: 100%;
}

.col-md-2 .row-4 {
    padding-bottom: 1%;
    width: 100%;
}

.col-md-4 .row-1 { 
    padding-bottom: 2%;
}

.col-md-4 .row-2 {
    padding-bottom: 10%;
}

.col-md-4 .row-3 {
    padding-bottom: none;
    width: 100%;
}

.col-md-3 {
    display: grid;
    grid-template-rows: repat(2, 1fr);
}

.col-md-4 {
    display: grid;
    grid-template-rows: repat(3, 1fr);
    padding-bottom: 7%;
    padding-right: 11%;
}

.driverz-pad {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.car-club-pad {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-logo-pad {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus {
    display: flex;
    padding-right: none;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.plus-spec {
    display: flex;
    padding-right: none;
    justify-content: center;
    align-items: center;
    width: 100%; 
    padding-bottom: 25%;
    padding-top: 25%;
}

/*
mt-3 .mint-button {
    height: 100%;
} */

.mint-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
}

.mint-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

.mt-2 {
    height: 100%;
}

.form-label,
.form-control,
.form-text {
    width: 75%;
    margin: 0 auto;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}


@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.intro-text {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
    padding-left: 7%;
    padding-right: 7%;
}

/* 
.form-label .text-white {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
}

*/

.form-text .text-muted {
    font-family: 'Lato-Regular';
    font-size: 15px;
    color: #f0f0f0;
    padding-bottom: 7%;
}

.heading-row {
    grid-row: 1;
    width: 100%;
    text-align: center;
    padding-top: 5%;
}

.driverz-page .heading-row h1 {
    color: #f0f0f0;
    font-size: 42px;
    font-family: 'Poppins-Bold';
}

.text-white {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
}

@media (max-width: 768px) {
    .row {
        grid-template-columns: 1fr;
    }
}