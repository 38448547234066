@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.turbo-1 h2 {
    font-family: 'Poppins-Bold';
    color: #f0f0f0;
    font-size: 42px;
}

.turbo-1 h4 {
    font-family: 'Poppins-Medium';
    color: #f0f0f0;
    font-size: 24px;
}

.turbo-container {
    display: grid;
    grid-template-rows: auto auto auto auto; /* Change 1fr to auto for content-based sizing */
    gap: 20px; /* Add some gap between rows */
    align-items: center; /* Center the content vertically within each row */
    text-align: center; /* Center the text horizontally within each row */
}

.ticket-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Distribute columns evenly */
    gap: 20px;
    justify-content: center;
}

.ticket-column {
    text-align: center;
}

.ticket-column h5 {
    font-family: 'Poppins-Bold';
    font-size: 24px;
    color: #a6a6a6;
}

.ticket-column h6 {
    font-family: 'Poppins-Bold';
    font-size: 20px;
    color: #f0f0f0;
}

.ticket-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color:#a6a6a6;
}

.text-turbo-column {
    padding-left: 12.5%;
}

.text-turbo-column h5 {
    font-family: 'Poppins-Bold';
    font-size: 32px;
    color: #f0f0f0;
    text-align: left;
}

.text-turbo-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color:#a6a6a6;
    text-align: left;
    
}

.text-turbo-column-new {
    padding-right: 12.5%;
}


.text-turbo-column-new h5 {
    font-family: 'Poppins-Bold';
    font-size: 32px;
    color: #f0f0f0;
    text-align: right;
}

.text-turbo-column-new p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color:#a6a6a6;
    text-align: right;
    
}

.content-text-row h5 {
    font-family: 'Poppins-Bold';
    font-size: 32px;
    color: #f0f0f0;
    
}

.content-text-row p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color:#a6a6a6;
}


.ticket-column img {
    width: 50%; /* Set a fixed width for the images */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove extra space below the images */
    margin: 0 auto; /* Center the images horizontally */
    border: 2px solid #a6a6a6; /* Gray border for the video using hex code */
    border-radius: 8px;
    box-sizing: border-box; 
    object-fit: contain;
    margin-bottom: 7%;
}

.image-turbo-column img {
    width: 50%; /* Set a fixed width for the images */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove extra space below the images */
    margin: 0 auto; /* Center the images horizontally */
    border: 2px solid #a6a6a6; /* Gray border for the video using hex code */
    border-radius: 8px;
    box-sizing: border-box; 
    object-fit: contain;
    margin-bottom: 7%;
}


.turbo-button {
    border-radius: 5px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 18px;
    /*padding: 10px;*/
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;

}

.turbo-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

.drop-down {
    margin-bottom: 5%;
}

.arrow-tab {
    display: inline-block;
    width: 20px; /* Adjust the width to control the spacing */
}

.arrow-icon {
    content: "\f061"; /* Unicode character for the right arrow (Font Awesome) */
    font-family: 'Lato-Regular';
    color: #a6a6a6;
    font-size: 18px;
}

.attribute-list {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-top: 3%;
    padding-bottom: 3%;
}

.att-box {
    border: 2px solid #3a6e48;
    background-color: rgba(58, 110, 72, 0.3);
    border-radius: 5%;
    margin-left: 7%;
    margin-right: 7%;
    font-size: 24px;
}

.attribute {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
}

.relation {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
}

.payout-row {
    display: grid;
    grid-template-columns: auto auto auto;
}

.pay-box h4 {
    font-family: 'Poppins-Bold';
    font-size: 24px;
    color: #a6a6a6;
}

.turbo-ol {
    list-style: none;
    padding: 0;
    font-size: 18px;
    font-family: 'Lato-Regular';
    color: #a6a6a6;
}

.turbo-ul {
    text-align: left;
    padding: 2%;
    font-size: 18px;
    font-family: 'Lato-Regular';
    color: #a6a6a6;
}
@media screen and (max-width: 768px) {
    /* Stack grid items in a single column */
    .turbo-container, .ticket-row, .content-row, .payout-row, .attribute-list {
        grid-template-columns: 1fr;
    }

    /* Ensure text comes before images in the content-row */
    .content-row {
        grid-template-areas: 
            "text"
            "image";
    }

    .text-turbo-column, .text-turbo-column-new {
        grid-area: text;
        padding: 0 5%; /* Adjust padding */
        text-align: center; /* Center text for mobile */
    }

    .image-turbo-column, .image-turbo-column-new {
        grid-area: image;
        padding: 0 5%; /* Adjust padding */
    }


    .turbo-container, .ticket-row, .content-row, .payout-row, .attribute-list {
        grid-template-columns: 1fr;
        text-align: center; /* Center the text */
    }

    /* Center content within each column */
    .ticket-column, .image-turbo-column, .image-turbo-column-new, .text-turbo-column, .text-turbo-column-new, .content-text-row, .pay-box {
        text-align: center;
    }

    /* Adjust image sizes and centering */
    .ticket-column img, .image-turbo-column img, .image-turbo-column-new img {
        width: 75%; /* Resize images to fit screen */
        height: auto;
        margin-left: auto; /* Center images horizontally */
        margin-right: auto;
        display: block; /* Use block display to enable margin auto */
    }

    /* Adjust font sizes for smaller screens */
    /* ... existing font size adjustments ... */

    /* Adjust button size */
    .turbo-button {
        padding: 12px 20px;
    }

    /* Adjust attribute box margins */
    .att-box {
        margin: 10px;
    }

    /* Adjust overall layout padding and margins */
    .turbo-container, .ticket-column, .content-text-row, .pay-box {
        padding: 0 5%;
        margin: 0;
    }
}

/* Additional media query for very small screens */
@media screen and (max-width: 480px) {
    /* Further adjustments for smaller phones */
    .text-turbo-column, .text-turbo-column-new {
        padding-left: 5%;
        padding-right: 5%;
    }
}