@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.container-admin {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 7px;
}

.admin-1 {
    text-align: center;
    color: #f0f0f0;
    font-size: 36pt;
    font-family: 'Poppins-Bold';
    margin-top: 7%;
}

.admin-2 {
    text-align: center;
    color: #f0f0f0;
    font-size: 24pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 5%;
}

.title-label {
    text-align: center;
    color: #f0f0f0;
    font-size: 18pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 0.5%;
}
.mint-button {
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
    border-radius: 5pt;
    margin-top: 1em;
}

.mint-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

.bid-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the form takes up the full width of its container */
}



  .players-table {
    background-color: #f0f0f0; /* Background color of the table */
    color: #0f0f0f; /* Font color for the table */
    font-family: 'Lato-Regular'; /* Font family for the table */
}

/* To specifically target table headers */
.players-table th {
    background-color: #3a6e48; /* Background color of headers */
    color: #ffffff; /* Font color for headers */
}

/* To specifically target table rows/cells */
.players-table td {
    padding: 10px; /* Padding inside cells */
    border-top: 1px solid #0f0f0f; /* Border color for cell separation */
    border-left: 1px solid #0f0f0f;
    border-right: 1px solid #0f0f0f;
}

.games-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 12.5%;
}
  
.games-container h2 {
    font-family: 'Poppins-Bold';
    font-size: 18pt;
    color: #f0f0f0;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}

.auction-tile {
    border: 2px solid var(--primary-accent-color, #3a6e48); /* Fallback color is #ef131f */
    border-radius: 5px;
    background-color: rgba(194, 24, 7, 0.05); /* Secondary accent color with 10% transparency */
    padding: 20px; /* Add padding to ensure content does not touch the border */
    margin: 20px 0; /* Optional: Adds some space around each tile */
}

.silent-auction-video {
    width: 25%;
    height: auto;
    border-radius: 5px;

}

.bid-container {
    margin: 7px 0;
    text-align: center;
    color: #f0f0f0;
    font-family: 'Lato-Regular';
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bid-container input,
.bid-container button {
    margin: 10px;
    padding: 5px;
}

/* Style your section-divider and admin-2 as needed */




.section-divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #f0f0f0, rgba(0, 0, 0, 0));
    margin-top: 7%;
    margin-bottom: 3%;
  }
  
  .vroom-transfer-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* creates two columns with equal width */
    gap: 20px; /* optional: defines space between the columns */
    align-items: start; /* optional: aligns items to the start of the grid area */
  }

.vroom-balance-row {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    color: #f0f0f0; 
    font-family: Poppins-Bold;
}

.vroom-transfer-box {
    border: 2px solid #3a6e48;
    padding: 7px;
    border-radius: 3%;
    background-color: rgba(58, 110, 72, 0.3);
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the space between rows */
    margin-left: 25%;
    margin-right: 25%;
}

.vroom-input-row {
    display: flex;
    flex-direction: column;
}

.vroom-input-row input {
    margin-bottom: 7px; /* Space below input before any error message */
}

.invalid-feedback {
    font-family: 'Lato-Regular';
    color:#f0f0f0;
}

.transfer-box-header {
    text-align: center;
    font-family: 'Poppins-Bold';
    font-size: 24px;
    color: #f0f0f0;
}

.table-container, .checkout-container {

    overflow-x: auto; /* Ensures the table is responsive */
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 2%;
  }
  
  .custom-table {
    width: 100%; /* Makes the table take the full width of its container */
    border-collapse: collapse; /* Removes the space between borders */
  }
  
  .custom-table th, .custom-table td {
    text-align: left; /* Aligns text to the left */
    padding: 8px; /* Adds padding inside table cells */
    border: 1px solid #0f0f0f; /* Adds a light border to each cell */
    background-color: #f0f0f0;
    color: #0f0f0f;
  }
  
  .custom-table th {
    background-color: #3a6e48; /* Adds a green background to header cells */
    color: white; /* Changes the text color to white for header cells */
  }

  .pack-row-admin {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10em; /* Adjust the gap between columns */
    background-color: #0f0f0f; /* Main background color */
    margin-left: 12.5%;
    margin-right: 12.5%;
}


  .pack-column {
    text-align: center;
    font-family: 'Lato-Regular';
    color: #a6a6a6;
    padding: 20px;
    background-color: #1a1a1a; /* Slightly lighter than the background */
    border-radius: 5px; /* Optional: if you want rounded corners */
    position: relative; /* Set the position context for price-tag */
    border: 2px solid var(--primary-accent-color, #ef131f); /* Fallback color is #ef131f */
    border-radius: 5px;
}

.pack-column h3 {
    color: #a6a6a6;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    text-align: center;
}

.pack-column h4 {
    color: #a6a6a6;
    font-size: 18px;
    font-family: 'Lato-Bold';
    text-align: center;
}

.pack-column img {
  max-width: 62.5%; /* You can adjust this value as needed */
  height: auto;
  justify-self: center;
  opacity: 0.8;

  box-sizing: border-box; 
  object-fit: contain; /* Or use "cover" if you want the video to cover the entire container */
}

  