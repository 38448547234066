/* CarClubPage.css */
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../../assets/Fonts/Lato-Bold.ttf') format('truetype');
}

.container {
    overflow: auto; /* This will allow scrolling if the content inside container exceeds its bounds */
    height: 100vh;  /* Assuming you want the container to be at least as tall as the viewport */
    /* ... the rest of your properties ... */
}

.container::before {
    content: "";
    background-image: url('../../assets/Images/Car_Club.jpg');
    opacity: 0; /* This sets the transparency */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-size: 100% auto; 
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: scroll;
}


.car-club-page {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto auto;
    grid-gap: 16px;
}

.car-club-page .first-row img {
    width: 100%;
    height: auto;
    padding-bottom: 5%;
}

.pack-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10em; /* Adjust the gap between columns */
    background-color: #0f0f0f; /* Main background color */
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 3%;
}


  .pack-column-1 {
    text-align: center;
    font-family: 'Lato-Regular';
    color: #f0f0f0;
    padding: 20px;
    background-color: #1a1a1a; /* Slightly lighter than the background */
    border-radius: 5px; /* Optional: if you want rounded corners */
    position: relative; /* Set the position context for price-tag */
    /*border: 2px solid var(--primary-accent-color, #3a6e48);  */
    border-radius: 5px;
}

.pack-column-1 h3 {
    color: #a6a6a6;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    text-align: center;
    margin-bottom: 0.5%;
}

.pack-column-1 h4 {
    color: #a6a6a6;
    font-size: 18px;
    font-family: 'Lato-Bold';
    text-align: center;
}

.pack-column-1 img {
  max-width: 62.5%; /* You can adjust this value as needed */
  height: auto;
  justify-self: center;
  opacity: 0.8;

  box-sizing: border-box; 
  object-fit: contain; /* Or use "cover" if you want the video to cover the entire container */
}

.price-tag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3a6e48; /* Primary accent color */
    color: #c1e7c0;
    padding: 5px 10px;
    border-radius: 0 5px 0 5px; /* Rounded bottom left corner */
    font-family: 'Lato-Regular';
    font-size: 1.2em; /* Adjust the size as needed */
    z-index: 10;
}


.first-row h4 {
    text-align: center;
    color: #f0f0f0;
    font-size: 24px;
    font-family: 'Poppins-Medium';
}

.car-club-page h2 {
    font-family: 'Poppins-Bold';
    color: #F0F0F0;
    font-size: 42px;
    text-align: center;
}

.car-club-page .car-club-second-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
    align-items: center;
    justify-items: center;  
}

.car-club-page .car-club-second-row .left-column {
    padding-left: 5%;
}

.car-club-page .car-club-third-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
    align-items: center;
    justify-items: center;
}

.car-club-fourth-row {
    display: grid;
    grid-row: 4;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;

    justify-items: center;
}

.car-club-fifth-row {
    display: grid;
    grid-row: 5;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%; 
    align-items: center;
    justify-items: center;
}

.car-club-sixth-row {
 /* display: grid;
    grid-row: 6;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
    justify-items: center; */
    display: none;

}

.car-club-seventh-row {
 /* display: grid;
    grid-row: 7;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;*/
    display: none;
}

.car-club-eigth-row {
    display: grid;
    grid-row: 8;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
    justify-items: center;

}




.car-club-page .car-club-third-row .left-column img {
    width: 90%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.car-club-page .car-club-third-row .left-column video {
    width: 90%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}


.car-club-fifth-row .left-column video {
    width: 90%;
    height: auto;
    object-fit: cover;
    align-content: center;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}



.car-club-seventh-row .left-column video {
    width: 60%;
    height: auto;
    object-fit: cover;
    align-content: center;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.car-club-second-row .right-column img {
    width: 90%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: auto 0;
    border-radius: 10px; 
}

.car-club-fourth-row .right-column img {
    width: 90%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: auto 0;
    border-radius: 10px;
}

.car-club-sixth-row .right-column video {
    width: 50%;
    height: auto;
    object-fit: cover;
    align-tracks: center;
    display: block;
    margin: auto 0;
    border-radius: 10px;
}

.car-club-eigth-row .right-column video {
    width: 60%;
    height: auto;
    object-fit: cover;
    align-tracks: center;
    display: block;
    margin-left: 12.5%;
    border-radius: 10px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5%; /* Adjust as necessary to fill the container */
    width: 100%; /* Ensures the spinner is centered horizontally */
}


.car-club-third-row .right-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-fifth-row .right-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-seventh-row .right-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-seventh-row .right-column h4 {
    font-family: 'Poppins-Medium';
    font-size: 24px;
    color: #f0f0f0;
}

.car-club-page .car-club-second-row .left-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-fourth-row .left-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-sixth-row .left-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-sixth-row .left-column h4 {
    font-family: 'Poppins-Medium';
    font-size: 24px;
    color: #f0f0f0;
}

.car-club-eigth-row .left-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.car-club-eigth-row .left-column h4 {
    font-family: 'Poppins-Medium';
    font-size: 24px;
    color: #f0f0f0;
}

.car-club-third-row .right-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
}

.car-club-fifth-row .right-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
}

.car-club-seventh-row .right-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
}

.car-club-second-row .left-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
}

.car-club-second-row .left-column ul {
    font-size: 18px;
    font-family: 'Lato-Regular';
    color: #a6a6a6;
}

.car-club-second-row .left-column ul li {
    margin-bottom: 2%;
}

.car-club-fourth-row .left-column ul {
    font-size: 18px;
    font-family: 'Lato-Regular';
    color: #a6a6a6;
}

.car-club-fourth-row .left-column ul li {
    margin-bottom: 2%;
}

.car-club-fifth-row .right-column ul {
    font-size: 18px;
    font-family: 'Lato-Regular';
    color: #a6a6a6;
}

.car-club-fifth-row .right-column ul li {
    margin-bottom: 2%;
}

.car-club-fourth-row .left-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
}

.car-club-sixth-row .left-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
}

.car-club-eigth-row .left-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
}

.car-club-third-row .right-column {
    padding-right: 5%;
}

.car-club-fourth-row .left-column {
    padding-left: 17%;
}

.car-club-sixth-row .left-column {
    padding-left: 17%;
}

.car-club-eigth-row .left-column {
    padding-left: 17%;
}

.car-club-fifth-row .right-column {
    padding-right: 5%;
}



.custom-alert-text {
    color: #05A515;
    font-family: 'Lato-Bold';
}

.custom-sign-in-btn {
    background-color: transparent;
    border-color: #f0f0f0;
    color: #05A515;
    border-radius: 5px;
    box-shadow: none !important;
    border-style: solid;
}

.minus-btn {
    background-color: transparent;
    border-color: #f0f0f0;
    color: #f0c808;
    border-radius: 5px;
    box-shadow: none !important;
    border-style: solid;
}

.custom-sign-in-btn:hover {
    border-color: #f0f0f0;
    color: #05A515;
    background-color:rgba(5, 165, 21, 0.25);
}

.mint-count {
    background-color: transparent;
    border-color: #f0f0f0;
    color: #f0f0f0;
    border-style: solid;
    border-radius: 3px;
    text-align: center;
}

.big-minus-button,
.big-form-control,
.big-plus-button {
    font-size: 18px;
    padding: 7px;
    font-family: "Poppins-Bold";
}

.big-minus-button:hover {
    
    color: #ef131f !important;
    background-color: rgba(239, 19, 31, 0.25) !important;
}

.big-form-control {
    width: 40%;
}
/* 
.pack-row {
    padding-left: 2%;
    padding-right: 2%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5%;
} */

.pack-column {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    background-color: rgba(15, 15, 15, 0.6);
    place-items: center;
    /* border: 2px solid #f0f0f0; Green border for the column using hex code */
    border-radius: 8px;
}

.pack-column h3 {
    color: #a6a6a6;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    text-align: center;
}

.pack-column h4 {
    color: #a6a6a6;
    font-size: 18px;
    font-family: 'Lato-Bold';
    text-align: center;
}

.pack-column video {
  max-width: 75%; /* You can adjust this value as needed */
  height: auto;
  justify-self: center;
  opacity: 0.8;

  border: 2px solid #595959; /* Gray border for the video using hex code */
  border-radius: 8px;
  box-sizing: border-box; 
  object-fit: contain; /* Or use "cover" if you want the video to cover the entire container */
}

.vroom-image {
    width: 40%; /* Adjust this value based on your layout requirements */
    height: auto; /* Maintains the aspect ratio of the image */
    margin: 3.5em 0 2.5em 0;
  }
  



.padding-up {
    padding-top: 3%;
}

.mint-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
}

.mint-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

/* CSS for Desktop layout above */

/* CSS for Tablet layout */
@media (max-width: 1024px) {
    .car-club-page {
        grid-gap: 10px;
    }

    .car-club-page .first-row img {
        padding-bottom: 4%;
    }

    .car-club-page h2 {
        font-size: 36px;
    }

    .car-club-page .car-club-second-row {
        padding: 3%;
    }

    .car-club-third-row,
    .car-club-fourth-row {
        grid-template-columns: 1fr;
        padding-top: 5%;
    }

    .car-club-third-row .left-column img,
    .car-club-fourth-row .right-column img {
        width: 100%;
    }

    .car-club-third-row .right-column h3,
    .car-club-fourth-row .left-column h3 {
        font-size: 28px;
    }

    .car-club-third-row .right-column p,
    .car-club-fourth-row .left-column p,
    .car-club-fourth-row .left-column ul {
        font-size: 16px;
    }

    .car-club-third-row .right-column,
    .car-club-fourth-row .left-column {
        padding: 0;
    }

    .big-minus-button,
    .big-form-control,
    .big-plus-button {
        font-size: 16px;
        padding: 5px;
    }

    .mint-button {
        font-size: 20px;
        padding: 8px;
    }
}

/* Mobile layout with simplified grid and ordering */
@media (max-width: 768px) {
    .pack-column-1 img {
        max-width: 100%;

    }

    .vroom-image {
        width: 60%;
        margin: 2em 0 2em 0;
    }

    .car-club-page {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .pack-row {
        display: flex;
        flex-direction: column;
        gap: 2em; /* Stack elements in a single column */
    }

    .car-club-page .car-club-second-row,
    .car-club-page .car-club-third-row,
    .car-club-page .car-club-sixth-row,
    .car-club-page .car-club-seventh-row {
      display: none;
    }

    .car-club-page .car-club-eigth-row {
        display: none !important;
      }

    /* Ensure left and right columns stack vertically */
    .car-club-second-row .left-column,
    .car-club-second-row .right-column,
    .car-club-third-row .left-column,
    .car-club-third-row .right-column,
    .car-club-fourth-row .left-column,
    .car-club-fourth-row .right-column,
    .car-club-fifth-row .left-column,
    .car-club-fifth-row .right-column,
    .car-club-sixth-row .left-column,
    .car-club-sixth-row .right-column,
    .car-club-seventh-row .left-column,
    .car-club-seventh-row .right-column,
    .car-club-eigth-row .left-column,
    .car-club-eigth-row .right-column {
        width: 100%;
    }

    /* Adjust headings, text, and button styles for smaller screens */
    .car-club-page h2 {
        font-size: 30px;
    }

    .car-club-third-row .right-column h3,
    .car-club-second-row .left-column h3,
    .car-club-fourth-row .left-column h3 {
        font-size: 24px;
        text-align: center;
    }

    .car-club-third-row .right-column p,
    .car-club-fourth-row .left-column p,
    .car-club-second-row .left-column p {
        font-size: 18px;
        color: #f0f0f0;
        text-align: center;
    }

    .mint-button {
        font-size: 18px;
        padding: 6px;
    }

    /* Align items centrally where necessary */
    .center-item {
        justify-content: center;
        align-items: center;
    }
}


