@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../../assets/Fonts/Lato-Bold.ttf') format('truetype');
}


.racing-page {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-gap: 16px;
}

.img-row {
    width: 100%;
    height: auto;
    padding-bottom: 5%;
}

.img-row img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0;
}

.first-row h2 {
    font-family: 'Poppins-Bold';
    color: #F0F0F0;
    font-size: 42px;
    text-align: center;
}



.content-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16ox;
    padding-top: 7%;
    align-items: center;
    justify-items: center;
}

.content-row .text-column {
    padding-left: 5%;
}

.content-row .img-column img {
    width: 87%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.content-row-img-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
    align-items: center;
    justify-items: center;
}

.content-row-img-left .img-column img {
    width: 87%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px; 
}

.signup-row {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center;
}

.signup-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-row h2 {
    font-family: 'Poppins-Bold';
    color: #F0F0F0;
    font-size: 42px;
    text-align: center;
}

.mint-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
}

.mint-button:hover {  
    background-color: rgba(5, 165, 21, 0.5); 
}