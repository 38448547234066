@font-face {
    font-family: 'MenuFont';
    src: url('../../assets/Fonts/Racing_Sans_One/RacingSansOne-Regular.ttf');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}


.header .header-item .nav-links a {
    font-family: 'Poppins-Medium';
    color: #f0f0f0;
    font-size: 16px;
    text-shadow: -1px -1px 0 #ef131f,  
                  1px -1px 0 #ef131f,
                 -1px 1px 0 #ef131f,
                  1px 1px 0 #ef131f;
}

.header .header-item .nav-links a:hover {
    color: #f0f0f0;
    font-size: 18px;
    text-shadow: -0.25px -0.25px 0 #05a515,  
                  0.25px -0.25px 0 #05a515,
                 -0.25px 0.25px 0 #05a515,
                  0.25px 0.25px 0 #05a515;
}

.sign-in-btn {
    background-color: transparent;
    border-color: #f0f0f0;
    color: #f0f0f0;
    border-radius: 5px;
    box-shadow: none !important;
    border-style: solid;
}

.sign-in-btn:hover {
    background-color: rgba(5, 165, 21, 0.5);
    ;
}

.header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 20px;
    align-items: center;
}

.header-item {
    display: inline-block;

}

.header-item-logo {
  display: flex;
  padding-left: 7%;
}

  
.logo {
    width: 30%;
    height: auto;
}

.nav-links {
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-gap: 14px;
    font-family: 'Poppins-Medium';
    color: #f0f0f0;
    font-size: 16px;
    text-shadow: -0.25px -0.25px 0 #ff0000,  
                  0.25px -0.25px 0 #ff0000,
                 -0.25px 0.25px 0 #ff0000,
                  0.25px 0.25px 0 #ff0000;
    letter-spacing: 2px;
}

.nav-links a:hover {
    color: #f0f0f0;
    font-size: 18px;
    text-shadow: -0.25px -0.25px 0 #05a515,  
                  0.25px -0.25px 0 #05a515,
                 -0.25px 0.25px 0 #05a515,
                  0.25px 0.25px 0 #05a515;
}

.nav-links a {
    text-decoration: none;
    color: inherit;
}

.header-item-acct {
  display: inline-block
}

@media (min-width: 769px) {
  .nav-toggle-btn {
    display: none;
  }
}

@media (max-width: 768px) {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  
    .header-item:not(.sign-in) {
      width: 100%;
    }
  
    .logo {
      max-width: 80%;          /* Adjust the logo size as needed */
      height: auto;
      display: block;          /* Ensures it's treated as a block to center easily */
      margin: 0 auto;          /* Ensures it's centered if flexbox fails */
  }

    .header-item-logo {
      display: flex;
      justify-content: center; /* Centers the logo horizontally */
      align-items: center; /* Centers the logo vertically */
      padding-left: 0; /* Remove left padding to center */
      width: 100%;
  }
  
    .nav-links {
      display: none;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  
    .nav-links.open {
      display: block;
    }
  
    .nav-links a {
      display: block;
      padding: 10px;
    }
  
    .sign-in {
      text-align: center;
    }
  
   /* CSS for Mobile layout */

   .nav-toggle-btn {
        background-color: transparent;
        border-color: #f0f0f0;
        color: #f0f0f0;
        border-radius: 5px;
        box-shadow: none !important;
        border-style: solid;
    }
    
    .nav-toggle-btn:hover {
        background-color: rgba(5, 165, 21, 0.5);
    }
  
    
    /* Hide navigation links by default */
    .nav-links {
      display: none;
    }
  
    /* Show navigation links when toggle button is open */
    .nav-links.open {
      display: block;
    }
}
  