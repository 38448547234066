@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.utility-page {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-gap: 16 px;
}

.utility-page .first-row img {
    width: 100%;
    height: auto;
    padding-bottom: 5%;
}

.utility-page .second-row {
    width: 75%;
    grid-template-columns: auto;
}

.utility-page h2 {
    font-family: 'Poppins-Bold';
    color: #F0F0F0;
    font-size: 42px;
    text-align: center;
}
.third-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
}

.fifth-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
}

.sixth-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
}

.seventh-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
}

.eigth-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
}

.ninth-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%;
}

.tenth-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%; 
}

.eleventh-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 7%; 
}

.fourth-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding-top: 12%;
    align-items: start;
}
/*
.fifth-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    padding-top: 5%;
}

.fifth-row .column-tile {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 8px;
    align-items: start;
}

.fifth-row .column-tile img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 3px;
}

.fifth-row .column-tile h3 {
    font-family: "Poppins-Medium";
    font-size: 28px;
    color: #f0f0f0;
    margin: 0;
}

.fifth-row .column-tile p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
    margin: 0;
}
*/

.fifth-row .left-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;   
}

.third-row .left-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.seventh-row .left-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;   
}

.ninth-row .left-column video {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;   
}

.eleventh-row .left-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;  
}

.sixth-row .right-column video {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin-left: 12%;
    border-radius: 10px;
}

.eigth-row .left-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;  
}

.tenth-row .right-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;  
}

.fourth-row .right-column .full-height-img {
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    margin-left: 12%;
}

.fourth-row .text-column {
    padding-left: 7%;
}

.sixth-row .text-column {
    padding-left: 7%;
}

.eigth-row .text-column {
    padding-left: 7%;
}

.tenth-row .text-column {
    padding-left: 7%;
}

.text-column h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
}

.text-column p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
}

.text-column ul li {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #f0f0f0;
    margin-bottom: 1%;
}

.gen-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: transparent;
    border-style: solid;
    border-radius: 5px;
}

.gen-button:hover {

    background-color:rgba(5, 165, 21, 0.25);
}

