@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}


.tools-full-width-image {
    width: 100%;
    display: block;
    margin: auto;
}

.tools-page {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 16px;
}

.tools-page .first-row h2 {
    font-family: 'Poppins-Bold';
    font-size: 42px;
    color: #a6a6a6;
    text-align: center;
    width: 100%;
}

.second-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.column-tile {
    text-align: center;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 8px;
    align-items: center;
    justify-items: center;
    padding-top: 2%;
    align-content: center;
    border: none;
    border-radius: 10px;
    background-color: none;
    padding-bottom: 13%;

}

.img-larger {
    width: 150%;
    height: auto;
}

.img-larger-2 {
    width: 110%;
    height: auto;
}

.second-row .column-tile h3 {
    font-family: "Poppins-Medium";
    font-size: 28px;
    color: #f0f0f0;
}

.second-row .column-tile p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    padding-bottom: 3%;
    text-align: center;
    margin: 0 auto;
}

.second-row .column-tile img {
    margin-top: 3%;
    width: 30%;
    height: auto;
    object-fit: cover;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .second-row {
        grid-template-columns: 1fr; /* Set the column to take up the full width */
    }
    
    .column-tile {
        padding-top: 10%; /* Increase the top padding for better spacing */
        padding-bottom: 10%; /* Increase the bottom padding for better spacing */
    }
    
    .column-tile img {
        width: 60%; /* Adjust the image size for better visibility */
    }
}
