/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

:root {
  --primary-dark-color: #0f0f0f;
  --primary-accent-color: #ef131f;
  --secondary-dark-color: #2a2a2a;
  --secondary-accent-color: #c21807;
  --light-background-color: #f0f0f0;
  --light-gray-color: #a6a6a6;
  --dark-gray-color: #595959;
  --matte-green-color: #3a6e48;
  --matte-blue-color: #3a4f6e;
  --matte-purple-color: #4e3a6e;
  --matte-yellow-color: #bfbf00;
  --light-green-color: #93d092;
  --even-lighter-green-color: #c1e7c0;
}


/* A */
/* B */


button.btn-outline-primary {
  border-color: var(--light-gray-color);
  color: var(--light-gray-color);
  font-weight: bold;
}

button.btn-outline-primary:hover {
  background-color: var(--light-gray-color);
  color: var(--primary-dark-color);
}



/* C */

.custom-navbar {
  padding: 1rem;
}


/* D */
/* E */
/* F */
/* G */
/* H */

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
  align-items: center;
}

/* I */
/* J */
/* K */
/* L */

@media (max-width: 768px) {
  .logo-desktop {
    width: 50%;
    height: auto;
  }
}

.logo-desktop{
  width: 15%;
  height: auto;
}


/* M */
/* N */


.nav-link {
  margin-right: 1rem;
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.nav-links {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
}

.nav-links a {
  text-decoration: none;
  color: inherint;
}

.nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
  box-shadow: none;
}



/* Potential old below */






.navigation-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-nav a{
  position: relative;
  border: none;
}
.navbar-nav a::after{
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 4px;
  background-color: rgb(50, 94, 255);
  content: "";
  z-index: 1;
  transform: translate(-50%, -50%) scaleX(0);
  transition: transform 0.2s ease 0s;
}
.navbar-nav a:hover::after{
  transform: translate(-50%, -50%) scaleX(1);
}
.navbar-nav .active{
  border-bottom: 3px solid #325eff!important;
}



/* O */
/* P */
/* Q */
/* R */
/* S */
/* T */
/* U */
/* V */
/* W */
/* X */
/* Y */
/* Z */





body{
  background-color: #0f0f0f!important;
}


.font-weight-bold{
  font-weight: bold;
}


.card{
  height: 100%!important;
}

.coming-pad{
  margin: 550px;
}

.tools-pad{
  padding: 28px;
  border-radius: 20px;
  background-color: hsla(0,0%,100%,.3);
  height: 100%;
}
.tools-row{
  margin-bottom: 300px;
}

.font-italic{
  font-style: italic;
}

.font-13{
  font-size: 13px;
}

.accordion{
  --bs-accordion-bg: #212529!important;
  --bs-accordion-color: white!important;
  --bs-accordion-btn-color: white!important;
}
.accordion-button{
  font-weight: bold!important;
}

.w-full{
  width: 100%;
}

.form-input{
  background-color: #3c3c3c!important;
  border: none!important;
  caret-color: white;
  color: white!important;
}
.form-check-input{
  background-color: #3c3c3c!important;
}
