@font-face {
  font-family: 'MenuFont';
  src: url('../../assets/Fonts/Racing_Sans_One/RacingSansOne-Regular.ttf');
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background-color: #0f0f0f;
  padding: 2rem;
  color: white;
}

.footer-left {
  text-align: left;

  color: #3a6e48;
}

.user-info-text {
  margin: 0;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
}

.footer-center {
  text-align: center;
  display: flex;
  justify-content: center;
}

.footer-center > a {
  color: #c21807;
  transition: color 0.3s ease;
  margin-right: 2rem;
}

.footer-center > a:last-child {
  margin-right: 0;
}

.footer-center > a:hover {
  color: #3a6e48;
}

.footer-right {
  text-align: right;
}

.terms-conditions {
  color: #ffffff;
  text-decoration: none;
}

.terms-conditions:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    text-align: center;
    padding: 1rem;
  }

  .footer-left,
  .footer-right {
    text-align: center;
  }

  .footer-center {
    margin-top: 1rem;
  }
}
