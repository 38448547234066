@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.shop-page {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10em; /* Adjust the gap between columns */
    background-color: #0f0f0f; /* Main background color */
    margin-left: 12.5%;
    margin-right: 12.5%;
}

.title-label {
    text-align: center;
    color: #f0f0f0;
    font-size: 18pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 2%;
}

.shop-main h1 {
    text-align: center;
    color: #f0f0f0;
    font-size: 36pt;
    font-family: 'Poppins-Bold';
    margin-top: 3%;
    margin-bottom: 3%;
}

.bonus-text {
    font-size: 0.8em; /* Smaller font size for bonus amounts */
  }
  

.shop-main h2 {
    text-align: center;
    color: #f0f0f0;
    font-size: 24pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 3%;
}

.section-divider-2 {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #f0f0f0, rgba(0, 0, 0, 0));
    margin-top: 0.7%;
    margin-bottom: 2%;
  }
  

.buy {
    text-align: center;
    font-family: 'Lato-Regular';
    color: #f0f0f0;
    padding: 20px;
    background-color: #1a1a1a; /* Slightly lighter than the background */
    border-radius: 5px; /* Optional: if you want rounded corners */
    position: relative; /* Set the position context for price-tag */
}

.price-tag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3a6e48; /* Primary accent color */
    color: #c1e7c0;
    padding: 5px 10px;
    border-radius: 0 5px 0 5px; /* Rounded bottom left corner */
    font-family: 'Lato-Regular';
    font-size: 1.2em; /* Adjust the size as needed */
    z-index: 10;
}

.coin-bag {
    border-radius: 10%;
    width: 50%; /* Adjust if necessary */
    height: auto;
    margin-top: 20px; /* Added space above the image */
    margin-bottom: 10px; /* Added space below the image */
}

.vroom-amount {
    font-size: 1.4em; /* Adjust the size as needed */
    margin: 10px 0; /* Give some space above and below the text */
    color: #f0f0f0; /* Or another color that contrasts with the tile background */
    font-family: 'Poppins-Bold';
    letter-spacing: 0.025em;
}

.auction-tiles-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em; /* Adjust the gap between tiles as needed */
    margin: auto; /* Center the grid horizontally */
    padding-left: 5%; /* Add padding around the grid */
    padding-right: 5%;
}

.auction-tile-new {
    border: 2px solid;
    border-color: #1a1a1a; /* Fallback color is #ef131f */
    border-radius: 5px;
    background-color: #1a1a1a; /* Secondary accent color with 10% transparency */
    padding: 20px; /* Add padding to ensure content does not touch the border */
    margin: 20px 0; /* Optional: Adds some space around each tile */
}

.silent-auction-video {
    width: 50%;
    height: auto;
    border-radius: 5px;

}

.dimension-x {
    width: 50%;
    height: auto;
    border-radius: 5px;
}

.doodles {
    width: 53%;
    height: auto;
    border-radius: 5px;
}

.flovatar {
    width: 60%;
    height: auto;
    border-radius: 5px;
}

.bid-container {
    text-align: center;
    color: #f0f0f0;
    font-family: 'Lato-Regular';
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5%; /* Adjusted to add spacing on the top */
    padding-bottom: 10px; /* Adjusted to add spacing on the bottom */
}

.bid-container p {
    text-align: center;
    color: #f0f0f0;
    font-family: 'Lato-Regular';
    font-size: 14pt;
    margin: 5px 0; /* Reduced margin to bring <p> elements closer together */
}

.bid-container input,
.bid-container button {
    margin: 10px;
    padding: 5px;
}

.bid-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the form takes up the full width of its container */
}




.button {
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 18px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Poppins-Bold';
    background-color: #1a1a1a;
    border-style: solid;
    border-radius: 5pt;
    margin-top: 1em;
}

.button:hover {
    background-color: rgba(5, 165, 21, 0.5);
}

.balances-container {
    display: flex;
    justify-content: space-around;
    padding: 0 20px;

    border-radius: 10px; /* Optional: for rounded corners */
    margin: 20px 0; /* Optional: for spacing around the container */
  }
  
  .balance {
    text-align: center;
    padding: 20px; /* Add padding to each balance for spacing */
    border-radius: 5px; /* Optional: for rounded corners inside each balance */
    margin: 10px; /* Space between each balance item */
    background-color: #1a1a1a; /* Set background color */
    color: #f0f0f0; /* Light text color */
  }
  
  .balance-amount {
    font-size: 1.5em; /* Adjust the size as needed */
    font-family: 'Poppins-Bold';
  }

  .pack-row-vroom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10em; /* Adjust the gap between columns */
    background-color: #0f0f0f; /* Main background color */
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 3%;
}


  .pack-column-1-vroom {
    text-align: center;
    font-family: 'Lato-Regular';
    color: #f0f0f0;
    padding: 20px;
    background-color: #1a1a1a; /* Slightly lighter than the background */
    border-radius: 5px; /* Optional: if you want rounded corners */
    position: relative; /* Set the position context for price-tag */
    /*border: 2px solid var(--primary-accent-color, #3a6e48);  */
    border-radius: 5px;
}

.pack-column-1-vroom h3 {
    color: #a6a6a6;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    text-align: center;
    margin-bottom: 0.5%;
}

.pack-column-1-vroom h4 {
    color: #a6a6a6;
    font-size: 18px;
    font-family: 'Lato-Bold';
    text-align: center;
}

.pack-column-1-vroom img {
  max-width: 62.5%; /* You can adjust this value as needed */
  height: auto;
  justify-self: center;
  opacity: 0.8;

  box-sizing: border-box; 
  object-fit: contain; /* Or use "cover" if you want the video to cover the entire container */
}

/* wager.css */

.select-bid {
    background-color: #0f0f0f; /* White background */
    color: #f0f0f0; /* Dark text color */
    font-family: 'Lato-Regular';
    font-size: 16px; /* Adjust font size as needed */
    padding: 8px; /* Add some padding inside the dropdown */
    border-radius: 4px; /* Rounded corners */
    border: 1px solid #f0f0f0; /* Light gray border */
    width: 35%; /* Full width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    margin-bottom: 3%;
  }
  
  .select-bid:focus {
    outline: none; /* Removes the outline on focus for a cleaner look */
    border-color: #a6a6a6; /* Darker border color on focus */
  }
  

  
  

@media (max-width: 768px) {
    .shop-page {
        grid-template-columns: 1fr; /* Single column layout */
    }
    .buy {
        width: 100%; /* Full width */
        margin-bottom: 20px; /* Add space between items */
    }

    .auction-tiles-container, .pack-row-vroom {
        grid-template-columns: 1fr; /* Stack elements in a single column */
        gap: 2em; /* Adjust gap if needed */
      }
}
