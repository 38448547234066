@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

#countdown {
  display: flex;
  justify-content: center;
}

.time-section {
  text-align: center;
  margin: 0 10px;
  color: #f0f0f0;
  font-family: 'Poppins-Bold';
  font-size: 1.7em;
}

.time-label {
  font-size: 1.5rem;
  color: #f0f0f0;
  font-family: 'Lato-Regular';
}

.time-container {
  padding-bottom: 1.5%;
}

.table-container, .checkout-container {

    overflow-x: auto; /* Ensures the table is responsive */
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 2%;
  }
  
  .custom-table {
    width: 100%; /* Makes the table take the full width of its container */
    border-collapse: collapse; /* Removes the space between borders */
  }
  
  .custom-table th, .custom-table td {
    text-align: left; /* Aligns text to the left */
    padding: 8px; /* Adds padding inside table cells */
    border: 1px solid #0f0f0f; /* Adds a light border to each cell */
    background-color: #f0f0f0;
    color: #0f0f0f;
  }
  
  .custom-table th {
    background-color: #3a6e48; /* Adds a green background to header cells */
    color: white; /* Changes the text color to white for header cells */
  }
  
  .checkout-button {
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
    border-radius: 5pt;
    margin-top: 1em;

  }
  
  .checkout-button:hover {
    background-color: rgba(5, 165, 21, 0.5);
  }

  .balance-display h3 {
    margin-left: 12.5%;
    color: #f0f0f0;
    font-family: 'Arial', sans-serif;
  }
  
  .division-container {
    margin-bottom: 20px; /* Spacing between each division's section */
  }
  
  .division-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; /* Spacing between the header and the table */
  }
  
  .division-name {
    margin: 0 15px; /* Adjusts spacing around the name */
    color: #f0f0f0;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
  }
  
  /* Style for the buttons */
  .division-header button {
    background-color: transparent;
    color: #3a6e48;
    font-size: 18pt;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }

  .wager-main h1 {
    text-align: center;
    color: #f0f0f0;
    font-size: 5em;
    font-family: 'Poppins-Bold';
    margin-top: 3%;
  }
  .wager-main h2 {
    text-align: center;
    color: #a6a6a6;
    font-size: 3em;
    font-family: 'Poppins-Bold';
    margin-bottom: 5%;
  }

  .wager-2 h2 {
    text-align: center;
    color: #f0f0f0;
    font-size: 28pt;
    font-family: 'Poppins-Bold';
    margin-top: 3%;
  }

  .wager-2 p {
    color: #f0f0f0;
    font-size: 14pt;
    font-family: 'Lato-Regular';
    margin-top: 3%;
    text-align: center;
  }


  .process p {
    color: #f0f0f0;
    font-size: 14pt;
    font-family: 'Lato-Regular';
    margin-top: 3%;
    text-align: center;
  }

  .wager-3 {
    margin-left: 6.25%;
    margin-right: 6.25%;
  }
  .wager-4 h3 {
    text-align: center;
    color: #f0f0f0;
    font-size: 22pt;
    font-family: 'Lato-Regular';
  }

  .wager-3 h4 {
    text-align: center;
    color: #a6a6a6;
    font-size: 12pt;
    font-family: 'Lato-Regular';
    margin-bottom: 5%;
  }

  .wager-3 p {
    color: #f0f0f0;
    font-size: 9pt;
    font-family: 'Lato-Regular';
  }




  
.section-divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #f0f0f0, rgba(0, 0, 0, 0));
    margin-top: 7%;
    margin-bottom: 3%;
  }

  .section-divider-2 {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #f0f0f0, rgba(0, 0, 0, 0));
    margin-top: 0.7%;
    margin-bottom: 7%;
  }
  
  