.terms-container {
    text-align: center;
}

.terms-header {
    font-size: 32px;
    color: #f0f0f0;
}

.terms-paragraph {
    font-size: 18px;
    color: #a6a6a6;
}