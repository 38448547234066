@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.info-section {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Increase gap between columns */
    margin-bottom: 20px; /* Adjust as needed */
    padding: 0 10px; /* Add some padding on the sides */
}

.info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25em; /* Adjust based on the total width you prefer */
    border: 2px solid #3a6e48;
    border-radius: 5px;
    background-color: rgba(58, 110, 72, 0.3);
    padding: 15px; /* Add padding inside each column for more space */
}
  
.info-title {
    font-weight: bold;
    margin-bottom: 10px; /* Adjust as needed */ 
    font-size: 1.25em;
}

.info-value {
    font-weight: normal;
    font-size: 1em;
}

.vroom-balance-row h2 {
    text-align: center;
    color: #f0f0f0;
    font-size: 24pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 20px; /* Increase margin to separate the title from the info section */
}

.staking-button-row {
    display: flex;
    justify-content: center;
}

.vroom-transfer-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* creates two columns with equal width */
    gap: 20px; /* optional: defines space between the columns */
    align-items: start; /* optional: aligns items to the start of the grid area */
  }

.vroom-balance-row {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    color: #f0f0f0; 
    font-family: Poppins-Bold;
}

.vroom-transfer-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 2px solid #3a6e48;
    padding: 7px;
    border-radius: 3%;
    background-color: rgba(58, 110, 72, 0.3);
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the space between rows */
}

.vroom-input-row {
    display: flex;
    flex-direction: column;
}

.vroom-input-row input {
    margin-bottom: 7px; /* Space below input before any error message */
}

.invalid-feedback {
    font-family: 'Lato-Regular';
    color:#f0f0f0;
}

.transfer-box-header {
    text-align: center;
    font-family: 'Poppins-Bold';
    font-size: 24px;
    color: #f0f0f0;
}
  
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust as necessary to fill the container */
    width: 100%; /* Ensures the spinner is centered horizontally */
}


.mint-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
}

.mint-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

.garage-coming-pad {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 20px;
    margin-top: 0;
    
}

.garage-full-width-image {
    width: 100%;
    display: block;
    margin: auto;
}

.garage-sign-in-text {
    font-family: 'Lato-Regular';
    color:#f0f0f0;
    font-size: 36px; /* Adjust the size as needed */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 23%;
}

.garage-item-container {/*
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3%;*/
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3%;
    justify-content: center;
    padding-left: 3%;
    padding-right: 3%;

   

}

.garage-item {
    flex-basis: 0 0 calc(33% - 20px);
    box-sizing: border-box;
    border: 2px solid #3a6e48;
    padding: 2%;
    border-radius: 3%;
    margin-bottom: 3%;
    background-color: rgba(58, 110, 72, 0.3);
    /*display: flex;
    flex-direction: column;
    align-items: center; /* Center items vertically */
    /*justify-content: center;  Center items horizontally 
    flex-basis: 0 0 calc(50% - 20px);
    box-sizing: border-box;
    border: 2px solid #3a6e48;
    padding: 2%;
    border-radius: 3%;
    margin-bottom: 3%;
    background-color: rgba(58, 110, 72, 0.3);*/
}

.title-settings {
    font-family: 'Poppins-Bold';
    color: #f0f0f0;
    font-size: 48px;
    text-align: center;
}


.vroom-balance-row h2 {
    text-align: center;
    color: #f0f0f0;
    font-size: 24pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 3%;
}

.garage-main h2 {
    text-align: center;
    color: #f0f0f0;
    font-size: 24pt;
    font-family: 'Poppins-Bold';
    margin-bottom: 3%;
}


.section-divider-2 {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #f0f0f0, rgba(0, 0, 0, 0));
    margin-top: 0.7%;
    margin-bottom: 2%;
  }
  


@media screen and (max-width: 768px) {
    .garage-item-container {
      grid-template-columns: 1fr; /* Display only one column for mobile */
      padding-left: 5%; /* Adjust as needed */
      padding-right: 5%; /* Adjust as needed */
    }
  
    .garage-item {
      flex-basis: 0 0 calc(100% - 20px); /* Occupy full width */
      /* ...adjust other styles as needed for mobile layout... */
    }
  
    .info-section {
      display: flex;
      flex-direction: column;
      gap: 20px; /* Adjust gap for better spacing in mobile view */
    }
    .main .vroom-transfer-row {
        display: grid; /* Use grid to maintain the structure */
        grid-template-columns: 1fr; /* Make it a single column layout */
        
    }

    .main .vroom-transfer-box {
        width: 75%;
        margin: 1em 2.7em;
    }
  
  
    .info-column {
      width: auto; /* Allows each .info-column to take the width it needs, stacked vertically */
    }
  
  }