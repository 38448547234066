/* HomePage.css */
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

.home-page .third-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 7%;
}

.home-page .third-row .column-1,
.home-page .third-row .column-2,
.home-page .third-row .column-3 {
    display: grid;
    grid-template-rows: auto auto auto;
    align-items: center;
    grid-gap: 8px;
    text-align: center;
    width: 30%;
}

.home-page .third-row .column-1 {
    padding-left: 12.5%;
}

.home-page .third-row .column-3 {
    padding-right: 12.5%;
}

.home-page .third-row img {
    width: auto;
    max-width: 150px;
    height: auto;
    max-height: 150px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.home-page .third-row h3 {
    font-family: 'Poppins-Medium';
    font-size: 36px;
    color: #f0f0f0;
    text-align: center;
}

.home-page .third-row p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
    text-align: center;
}

.home-page {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 16px;
}

.home-page second-row {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 25%;
    padding-right: 25%;
}

.home-page .first-row img {
    width: 100%;
    height: auto;
    padding-bottom: 5%;
}

.home-page .first-row h2 {
    font-family: 'Poppins-Bold';
    color: #F0F0F0;
    font-size: 42px;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
}

.home-page .second-row {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0f0f0f;
    padding: 24px;
}

h2.custom-font-1 {
    font-family: 'Poppins-Bold';
    font-size: 42px;
    color: #f0f0f0;
    text-align: center;
    width: 100%;
    word-wrap: break-word;
}

.home-page .fourth-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    padding-top: 3%;
    padding-bottom: 3%;
}

.home-page .fifth-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    padding-top: 3%;
    padding-bottom: 3%;
}

.home-page .fourth-row .left-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.home-page .fifth-row .right-column img {
    width: 60%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: auto 0;
    border-radius: 10px;
    margin-left: 20%;
}

.home-page .fourth-row .right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 16px; /* Add some padding to separate text from the column border */
}

.home-page .fifth-row .left-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 42px;
}

.home-page .fourth-row .right-column h3 {
  font-family: 'Poppins-Regular';
  font-size: 32px;
  color: #f0f0f0;
  margin-bottom: 8px;
}

.home-page .fifth-row .left-column h3 {
    font-family: 'Poppins-regular';
    font-size: 32px;
    color: #f0f0f0;
    margin-bottom: 8px;
    align-items: left;
}

.home-page .fourth-row .right-column p {
  font-family: 'Lato-Regular';
  font-size: 18px;
  color: #a6a6a6;
}

.home-page .fifth-row .left-column p {
    font-family: "Lato-Regular";
    font-size: 18px;
    color: #a6a6a6;
}

.home-page .sixth-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    padding-top: 5%;
}

.home-page .sixth-row .column {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 8px;
    text-align: center;
}

.home-page .sixth-row img {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}

.home-page .sixth-row p {
    font-family: 'Lato-Regular';
    font-size: 18px;
    color: #a6a6a6;
}

.mint-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
}

.mint-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

@media (max-width: 768px) {
    .home-page {
        display: grid;
        grid-gap: 10px;
    }

    .home-page .first-row {
        grid-row: 1;
        grid-column: 1 / span 4;
    }

    .home-page .first-row .second-row{
flex-direction: column;
    }


    .home-page .third-row {
        grid-row: 2;
        grid-column: 1 / span 4;
        display: grid;
        grid-gap: 10px;
    }

    .home-page .third-row .column-1 {
        grid-row: 1;
        grid-column: 1 / span 4;
        padding-left: 0;
        width: 100%;
    }

    .home-page .third-row .column-2 {
        grid-row: 2;
        grid-column: 1 / span 4;
    }

    .home-page .third-row .column-3 {
        grid-row: 3;
        grid-column: 1 / span 4;
        padding-right: 0;
        width: 100%;
    }

    .home-page .third-row .column-1 p,
    .home-page .third-row .column-2 p,
    .home-page .third-row .column-3 p {
        padding-left: 2%;
        padding-right: 2%;
    }

    .home-page .fourth-row {
        grid-row: 3;
        grid-column: 1 / span 4;
        display: grid;
        grid-gap: 10%;
    }

    .home-page .fourth-row .left-column {
        grid-row: 1;
        grid-column: 1 / span 4;

    }

    .home-page .fourth-row .right-column {
        grid-row: 2;
        grid-column: 1 / span 4;
    }

    .home-page .fifth-row {
        grid-row: 4;
        grid-column: 1 / span 4;
        display: grid;
        grid-gap: 10px;
        
    }

    .home-page .fifth-row .right-column {
        grid-row: 1;
        grid-column: 1 / span 4;
        margin-top: 9%;

    }

    .home-page .fifth-row .left-column {
        grid-row: 2;
        grid-column: 1 / span 4;
        padding-left: 3.7%;
        text-align: left;
    }

    .home-page .sixth-row {
        grid-row: 5;
        grid-column: 1 / span 4;
        display: grid;
        grid-template-rows: auto auto auto auto;
        grid-gap: 10px;
    }

    .home-page .sixth-row .column-one {
        grid-row: 1;
        grid-column: 1 / span 4;
        text-align: center;
    }

    .home-page .sixth-row .column-two {
        grid-row: 2;
        grid-column: 1 / span 4;
        text-align: center;
    }

    .home-page .sixth-row .column-three {
        grid-row: 3;
        grid-column: 1 / span 4;
        text-align: center;
    }

    .home-page .sixth-row .column-four {
        grid-row: 4;
        grid-column: 1 / span 4;
        text-align: center;
    }

    .first-row h4 {
        font-size: 1.5em;
    }

    .mobile-hide-br {
        display: none;
    }

    .first-row img {
        display: none;
    }

}