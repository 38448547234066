@font-face {
    font-family: 'Lato-Regular';
    src: url('../assets/Fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../assets/Fonts/Lato-Bold.ttf') format('truetype');
}

.custom-text {
    font-family: 'Lato-Regular';
    color: #93d092;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust as necessary to fill the container */
    width: 100%; /* Ensures the spinner is centered horizontally */
}

.mint-button {
    border-radius: 2px;
    border-color: #f0f0f0;
    color: #f0f0f0;
    font-size: 24px;
    padding: 10px;
    font-family: 'Poppins-Bold';
    background-color: #0f0f0f;
    border-style: solid;
}

.mint-button:hover {
    
    background-color: rgba(5, 165, 21, 0.5);
    
}

.media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3%;
}

.font-style {
    font-family: 'Lato Bold';
    color: #f0c808;
    font-size: medium;
}

.trait-row p {
    color: #a6a6a6;
    font-family: 'Lato-Regular';
}

.trait-row p span {
    font-family: 'Lato-Bold';
    color: #f0f0f0;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.button-container p {
    margin: 5px;
}

.card-content {
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 5px;
}

.card-content img {
    /*width: 100%;
    height: auto;
    border-radius: 5px;
    display: block;
    margin: auto;  Center the image vertically and horizontally */
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.backcard-container {
  display: grid;
  grid-template-rows: repeat(15, auto); /* Set 15 rows with auto height */
  gap: 10px; /* Add some spacing between rows */
}


