@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../assets/Fonts/Poppins-Bold.ttf') format('truetype');
}


.container h1 {
    font-family: 'Poppins-Bold';
    font-size: 42px;
    color: #f0f0f0;
    text-align: center;
    width: 100%;
}